@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{
    .btn-solid{
        @apply transition rounded-full tracking-wider uppercase inline-block py-3 px-20 text-xs text-white text-center font-semibold leading-none bg-brand hover:bg-blue-900 rounded
    }

    .btn-outline{
        @apply transition rounded-full tracking-wider uppercase inline-block py-3 px-5 text-xs text-white text-center font-semibold leading-none border-2 border-white hover:bg-white rounded hover:text-brand
    }

    .btn-outline-dark{
        @apply transition rounded-full  uppercase inline-block py-2 px-8 text-xs text-brand text-center font-semibold leading-none border-2 border-brand hover:bg-white rounded hover:text-brand
    }
}
